import React from 'react'
import { conMoneda, Moneda } from '../../../Models/General';

interface Props {
    change: (r: string) => void
    defaultValue: string

}
interface State {
    Monedas: Moneda[] | 'carro'
}
export default class MonedaSelector extends React.Component<Props, State>{
    constructor(p: Props) {
        super(p);
        this.state = { Monedas: 'carro' };
    }
    setUsers(c: Moneda[]) {
        if(Array.isArray(c))
        this.setState({ Monedas: c })
    }
    render() {
        return <select style={{width:'calc(100% - 100px'}} onChange={this.setRol.bind(this)} className="form-control" value={this.props.defaultValue}>
            <option value={-1}>SELECCIONAR MONEDA</option>
            {(this.state.Monedas !== 'carro') ? this.state.Monedas.map(c => {
                return <option key={"Monedas" + c.codigo} value={c.descripcion}>{c.descripcion}</option>
            }) : <></>}
        </select>
    }
    componentDidMount() {
        this.set();
    }
    setRol(Rol: React.ChangeEvent<HTMLSelectElement>) {
        let value = Rol.currentTarget.value as string
        this.props.change(value);
    }
    set() {
        conMoneda().lista(this.setUsers.bind(this));
    }
}